<template>
  <v-dialog
    id="form_view"
    v-if="bActive"
    overlay-color="primary"
    overlay-opacity=".97"
    persistent
    :fullscreen="smUp ? false: true"
    max-width="40%"
    v-model="bActive">
    <v-card
      :shaped="smUp"
      class="dialog_card_container">
      <!-- Start close icon -->
      <v-layout
        row
        justify-end>
        <v-btn
          style="margin-right: 10px"
          icon
          fab
          small
          color="grey"
          text
          @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-layout>
      <!-- End close icon -->
      <!-- Start dialog title -->
      <v-container
        style="margin-top: -30px">
        <v-layout
          row
          justify-center>
          <p
            v-text="dialog_title"
            class="dialog_title_text"
          />
        </v-layout>
      </v-container>
      <!-- End dialog title -->
      <!-- Start form containr -->
      <v-container>
        <v-layout 
          wrap
          row
          justify-center>
          <v-layout
            wrap
            row
            :align-center="!smUp"
            style="max-width: 80%">
            <!-- Start type textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="type_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-select
                v-model="outages_to_edit.iType"
                color="primary"
                append-icon="mdi-chevron-down"
                :items="type_options"
                item-text="text"
                item-value="value"
                :placeholder="type_text"
              />
            </v-layout>
            <!-- End type textfield -->
            <!-- Start status time textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="status_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-select
                v-model="outages_to_edit.iStatus"
                color="primary"
                append-icon="mdi-chevron-down"
                :items="status_options"
                item-text="text"
                item-value="value"
                :placeholder="status_text"
              />
            </v-layout>
            <!-- End status time textfield -->
            <!-- Start alarm textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="alarm_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-text-field 
                v-model="outages_to_edit.sAlarmActivated"
                color="primary"
                :placeholder="alarm_text"
              />
            </v-layout>
            <!-- End alarm textfield -->
            <!-- Start recovery time textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="recovery_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-select
                v-model="outages_to_edit.sEstimatedRecoveryTime"
                color="primary"
                append-icon="mdi-chevron-down"
                :items="time_options"
                :placeholder="recovery_text"
              />
            </v-layout>
            <!-- End recovery time textfield -->
            <!-- Start location textfield -->
            <v-layout
              row
              :justify-start="smUp"
              :justify-center="!smUp">
              <p
                class="field_texts"
                v-text="location_text"
              />
            </v-layout>
            <v-layout
              row
              justify-center
              class="password_layout">
              <v-select
                v-model="outages_to_edit.iLocation"
                color="primary"
                append-icon="mdi-chevron-down"
                :items="location_options"
                item-text="text"
                item-value="value"
                :placeholder="location_text"
              />
            </v-layout>
            <!-- End location textfield -->
            <v-layout
              v-if="outages_to_edit.iType == 1"
              row
              wrap>
              <v-flex
                xs12
                sm6
                md6>
                <!-- Start KIA MW textfield -->
                <v-layout
                  row
                  :justify-start="smUp"
                  :justify-center="!smUp">
                  <p
                    class="field_texts"
                    v-text="kiaMW_text"
                  />
                </v-layout>
                <v-layout
                  row
                  justify-center
                  class="password_layout">
                  <v-text-field 
                    :rules="rulesMW"
                    style="margin-top: 30px"
                    v-model="outages_to_edit.iKiaMW" 
                    type="number" 
                    label="Number" 
                    append-outer-icon="mdi-plus" 
                    @click:append-outer="incrementKIA()" 
                    prepend-icon="mdi-minus" 
                    @click:prepend="decrementKIA()"
                    :placeholder="kiaMW_text"
                  />
                </v-layout>
                <!-- End KIA MW textfield -->
              </v-flex>
              <v-flex 
                sm6
                xs12
                md6
              >
                <!-- Start MOBIS MW textfield -->
                <v-layout
                  row
                  :justify-start="smUp"
                  :justify-center="!smUp">
                  <p
                    class="field_texts"
                    v-text="mobisMW_text"
                  />
                </v-layout>
                <v-layout
                  row
                  justify-center
                  class="password_layout">
                  <v-text-field 
                    :rules="rulesMW"
                    style="margin-top: 30px"
                    v-model="outages_to_edit.iMobisMW" 
                    type="number" 
                    label="Number" 
                    append-outer-icon="mdi-plus" 
                    @click:append-outer="incrementMOBIS()" 
                    prepend-icon="mdi-minus" 
                    @click:prepend="decrementMOBIS()"
                    :placeholder="kiaMW_text"
                  />
                </v-layout>
                <!-- End MOBIS MW textfield --> 
              </v-flex>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-container>
      <!-- End form container -->
      <v-container>
        <!-- Center button layout -->
        <v-layout
          row
          justify-center>
          <!-- Limit button layout extension -->
          <v-layout
            row
            style="max-width: 80%">
            <v-btn
              color="red"
              text
              @click="close">
              {{cancel_btn_txt}}
            </v-btn>
            <v-spacer>
            </v-spacer>
            <v-btn
              color="primary"
              dense
              :loading="load_edit_btn"
              :disabled="load_edit_btn"
              @click="confirm_edit">
              {{submit_btn_txt}}
            </v-btn>
          </v-layout>
          <!-- Limit button layout extension -->
        </v-layout>
        <!-- Center button layout  -->
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
// TYPE LEGEND: 1: Unscheduled partial, 2: Unscheduled total. 3: Scheduled total 4: Scheduled total
export default {
  data: () => ({
    rulesMW: [
      v => !!v || 'Required',
      v => v >= 0 || 'No deben ingresarese valores negativos.'
    ],
    kiaMW_text: 'Kia MW',
    mobisMW_text: 'Mobis MW',
    location_text: 'Location',
    status_text: 'Consumer Supply Current Status',
    recovery_text: 'Estimated Recovery Time (hrs)',
    alarm_text: 'Alarm activated',
    type_text: 'Type of outage',
    time_options: [
      'Immediately',
      '10 minutes',
      '20 minutes',
      '30 minutes',
      '40 minutes',
      '50 minutes',
      '1 hour',
      '70 minutes',
      '80 minutes',
      '90 minutes',
      '100 minutes',
      '110 minutes',
      '2 hours',
      '130 minutes',
      '140 minutes',      
      '150 minutes',
      '160 minutes',
      '170 minutes',
      '3 hours',
      '4 hours',
      '5 hours',
      '6 hours',
      '7 hours',
      '8 hours',
      '9 hours',
      '10 hours',
      '11 hours',
      '12hours',
      '13 hours',
      '14 hours',
      '15 hours',
      '16 hours',
      '17 hours',
      '18 hours',
      '19 hours',
      '20 hours',
      '21 hours',
      '22 hours',
      '23 hours',
      '24 hours',
      '48 hours',
      '72 hours',
      '72 hours +',
    ],
    type_options: [
      {
        text: 'Unspecified',
        value: 0
      },
      {
        text: 'Partial',
        value: 1
      },
      {
        text: 'Total',
        value: 2
      }
    ],
    location_options: [
      {
        text: 'Unspecified',
        value: 0
      },
      {
        text: 'Inside the fence',
        value: 1
      },
      {
        text: 'Outside the fence',
        value: 2
      },
      {
        text: 'Transmission Line',
        value: 3
      }
    ],
    status_options: [
      {
        text: 'Unspecified',
        value: 0
      },
      {
        text: 'Supplying to Kia & Mobis',
        value: 1
      },
      {
        text: 'Only Kia',
        value: 2
      },
      {
        text: 'Only Mobis',
        value: 3
      },
      {
        text: 'Limited or partial supply to Kia and Mobis',
        value: 4
      },
      {
        text: 'Limited or partial supply to Kia',
        value: 5
      },
      {
        text: 'Limited or partial supply to Mobis',
        value: 6
      }
    ],
    submit_btn_txt: 'Save',
    cancel_btn_txt: 'Cancel',
    dialog_title: 'Preliminary Report',
    load_edit_btn: false,
  }),
  props: {
    bActive: Boolean,
    outages_to_edit: Object
  },
  watch: {
    outageTypeWatch: function () {
      if (this.outages_to_edit.iType !== 1) {
        this.outages_to_edit.iMobisMW = 0
        this.outages_to_edit.iKiaMW = 0
      }
    },
    outageKia: function () {
      if (this.outages_to_edit.iKiaMW < 0) {
        this.outages_to_edit.iKiaMW = 1
        this.$store.commit('toggle_alert', {color: 'red', text: 'El valor debe de los MW debe de ser mayor a 0.'})
      }
    },
    outageMobis: function () {
      if (this.outages_to_edit.iMobisMW < 0) {
        this.outages_to_edit.iMobisMW = 1
        this.$store.commit('toggle_alert', {color: 'red', text: 'El valor debe de los MW debe de ser mayor a 0.'})
      }
    }
  },
  methods: {
    incrementKIA () {
        this.outages_to_edit.iKiaMW = parseInt(this.outages_to_edit.iKiaMW,10) + 1
    },
    decrementKIA () {
      if (this.outages_to_edit.iKiaMW > 0) {
        this.outages_to_edit.iKiaMW = parseInt(this.outages_to_edit.iKiaMW,10) - 1
      }
    },
    incrementMOBIS () {
      this.outages_to_edit.iMobisMW = parseInt(this.outages_to_edit.iMobisMW,10) + 1
    },
    decrementMOBIS () {
      if (this.outages_to_edit.iMobisMW > 0) {
        this.outages_to_edit.iMobisMW = parseInt(this.outages_to_edit.iMobisMW,10) - 1
      } 
    },
    close () {
      this.$emit('close')
    },
    confirm_edit () {
      this.load_edit_btn = true
      var outage_update = {
        iType: this.outages_to_edit.iType,
        sAlarmActivated: this.outages_to_edit.sAlarmActivated,
        sEstimatedRecoveryTime: this.outages_to_edit.sEstimatedRecoveryTime,
        iStatus: this.outages_to_edit.iStatus,
        iLocation: this.outages_to_edit.iLocation,
        iKiaMW: this.outages_to_edit.iKiaMW,
        iMobisMW: this.outages_to_edit.iMobisMW
      }
      var body = encode.encodeJSON(outage_update)
      var outageId = this.outages_to_edit._id
      db.put(`${uri}/v2/api/outages/` + outageId, body, {
        headers: {
          'Authorization': this.usr_token,
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      })
      .then(resp => {
        this.load_edit_btn = false
        const alert = {
          color: 'success',
          text: resp.data.message 
        }
        this.$store.commit('toggle_alert', alert)
        this.close()
        this.$emit('update')
        // remove timer if eliminated element is the element the timer is set up for
        var retrievedObject = JSON.parse(localStorage.getItem('OutageEdit'))
        if (retrievedObject._id === this.outage_id && (localStorage.getItem('timer-status') == 'true' && localStorage.getItem('date-start') !== null)) {
          this.$router.go()
          localStorage.setItem('timer-status', 'false')
          localStorage.setItem('date-start', null)
          localStorage.removeItem('OutageEdit')
        }
      })
      .catch(err => {        
        this.load_edit_btn = false
        const alert = {
          color: 'fail',
          text: err.response.data.message 
        }
        this.$store.commit('toggle_alert', alert)
      })

    },
  },
  computed: {
    outageTypeWatch () {
      return this.outages_to_edit.iType
    },
    outageKia () {
      return this.outages_to_edit.iKiaMW
    },
    outageMobis () {
      return this.outages_to_edit.iMobisMW
    },
    usr_token () {
      return 'Bearer ' + this.$store.state.token
    },
    smUp () {
      return this.$vuetify.breakpoint.smAndUp
    }
  }
}
</script>
<style scoped>
  .field_texts {
    margin-bottom: 0px;
    font-family: 'Open sans', sans-serif;
    color: 'black';
    font-size: 12px;
    letter-spacing: .1px;
  }
  .password_layout {
    width: 100%; 
    margin-bottom: 10px
  }
  .dialog_card_container {
    padding: 5%
  }
</style>